<template>

        <v-snackbar
            v-model="$parent.showToggle"
            bottom
            :color="color"
            elevation="24"
            :timeout="timeout"
            >
            <template v-slot:action="{ attrs }">
                <v-icon
                    color="white"
                    v-bind="attrs"
                    @click="closeToggle()"
                >
                mdi-close
                </v-icon>
            </template>
            {{message}}
        </v-snackbar>



</template>

<script>
export default {
    props: ['message','color','showToggle'],
    data () {
        return {    
            toggleVal:this.$parent.showToggle,
            timeout: 2500,
        }
    },
    methods: {
      closeToggle() {
          this.$parent.showToggle = false;
      }
    },
    mounted() {
        
    },
    watch: {
    },

}
</script>